import React, { FC } from "react";
import { Tag } from "@/components/Tag";

export interface BlogHeroSectionProps {
  popularTags: string[];
}

export const BlogHeroSection: FC<BlogHeroSectionProps> = ({
  popularTags,
  children,
}) => (
  <section className="hero-section color-bg-light">
    <div className="l-wrapper clearfix">
      <div className="l-5-cols-tablet">{children}</div>
      <div className="l-7-cols-tablet border-before l-with-gutter-bottom-mobile hidden-mobile">
        <div className="d-block color-faded">POPULAR TOPICS</div>
        {popularTags.map((tag) => (
          <Tag key={tag} tag={tag} />
        ))}
      </div>
    </div>
  </section>
);

import React, { FC } from "react";

export interface BlogPostBoxProps {
  url: string;
  date: string;
  title: string;
  lead: string;
  coverImage: string;
  imageAlt: string;
  thumbnailImage?: string;
  isHomepage?: boolean;
  isCareerPage?: boolean;
}

export const BlogPostBox: FC<BlogPostBoxProps> = ({
  url,
  date,
  title,
  lead,
  coverImage,
  imageAlt,
  thumbnailImage,
  isHomepage,
  isCareerPage,
}) => (
  <a className="d-block" href={url}>
    <figure className="pos-rel">
      <picture className="image-object">
        <source
          media="(max-width: 420px)"
          srcSet={`${coverImage}?${
            isHomepage ? "w=420&h=220" : "w=480&h=250"
          }&fit=thumb`}
        />
        <source
          media="(max-width: 480px)"
          srcSet={`${coverImage}?${
            isHomepage ? "w=480&h=250" : "w=600&h=360"
          }&fit=thumb`}
        />
        <source
          media="(min-width: 480px)"
          srcSet={`${thumbnailImage || coverImage}?${
            isHomepage ? "w=320&h=220" : "w=680&h=400"
          }&fit=thumb`}
        />
        <img
          alt={imageAlt}
          src={`${thumbnailImage || coverImage}?w=320&h=150&fit=thumb`}
        />
      </picture>
    </figure>
    <div className="image-card-content color-bg-negative">
      <div className="post-date-inline">{date.toUpperCase()}</div>
      {isCareerPage ? (
        <h3 className="l-push-bottom">{title}</h3>
      ) : isHomepage ? (
        <h4 className="color-primary">{title}</h4>
      ) : (
        <h2 className="title">{title}</h2>
      )}

      <p>{lead}</p>
    </div>
    <div className="color-bg-negative align-end">
      <button className="button button-transparent">
        <span className="color-primary d-inline-block">READ MORE</span>
        <img
          alt=""
          className="d-inline-block"
          src="/images/icons/chevron.svg"
          style={{
            verticalAlign: "middle",
            marginTop: "-2.5px",
            marginLeft: "5px",
          }}
          width="8"
        />
      </button>
    </div>
  </a>
);

import React, { FC } from "react";
import { graphql, PageProps } from "gatsby";
import { MainLayout } from "@/layouts/MainLayout";
import { SEO } from "@/components/basic/SEO";
import Helmet from "react-helmet";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { BlogPostBox } from "@/components/BlogPostBox";
import { Link } from "@/components/basic/Link";
import { BlogHeroSection } from "@/components/BlogHeroSection";

const TagTemplate: FC<PageProps<GatsbyTypes.Query>> = ({
  data: {
    allContentfulNewsPost: blogPostsContent,
    allContentfulProductLandingPage: productLandings,
    allContentfulProfessionalServicesLandingPage: professionalServicesLandings,
  },
  pageContext: { tag, popularTags, buildDate },
}) => {
  const orderedLandings = productLandings.edges.concat(
    professionalServicesLandings.edges
  );

  return (
    <MainLayout>
      <Helmet>
        <body className="ltr tag-page" />
      </Helmet>
      <SEO
        title="Growing Digital Travel & Commerce"
        description="Growing Digital Travel & Commerce"
        keywords={tag}
      />
      <BlogHeroSection popularTags={popularTags}>
        <h1 className="color-primary text-capital">
          ANIXE: {tag.toLowerCase()}
        </h1>
      </BlogHeroSection>
      <section className="l-wrapper color-bg-negative clearfix">
        <div className="clearfix l-with-dbl-vertical-gutters-mobile">
          {orderedLandings.length > 0 &&
            orderedLandings.map(({ node }) => (
              <div
                key={node.descriptionTitle}
                className="d-block l-6-cols-tablet l-4-cols-desktop clearfix l-dbl-push-bottom"
              >
                <BlogPostBox
                  url={`/${node.url}`}
                  date={`${buildDate[1]} ${buildDate[0]} ${buildDate[2]}`}
                  title={node.descriptionTitle}
                  lead={node.lead.lead}
                  coverImage={node.tagPageImage.file.url}
                  imageAlt="preview"
                  boxType="tag"
                />
              </div>
            ))}
          {blogPostsContent.edges.map(({ node }) => (
            <div
              key={node.title}
              className="d-block l-6-cols-tablet l-4-cols-desktop clearfix l-dbl-push-bottom"
            >
              <BlogPostBox
                url={`/blog/${node.url}`}
                date={node.date}
                title={node.title}
                lead={node.lead}
                coverImage={node.coverImage.file.url}
                imageAlt={node.coverImage.title}
              />
            </div>
          ))}
        </div>
      </section>
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
        <Link
          classname="button button-quaternary-negative-dark button-estimate-project"
          url="/estimate-project"
          text="estimate project"
        />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default TagTemplate;

export const query = graphql`
  query($tag: [String]) {
    allContentfulNewsPost(
      filter: { url: { ne: null }, tags: { in: $tag } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          date(formatString: "DD MMM YYYY", locale: "en-US")
          title
          url
          tags
          lead
          coverImage {
            title
            file {
              url
            }
          }
        }
      }
    }
    allContentfulProductLandingPage(filter: { showForTags: { in: $tag } }) {
      edges {
        node {
          lead {
            lead
          }
          url
          descriptionTitle
          tagPageImage {
            file {
              url
            }
          }
        }
      }
    }
    allContentfulProfessionalServicesLandingPage(
      filter: { showForTags: { in: $tag } }
    ) {
      edges {
        node {
          lead {
            lead
          }
          url
          descriptionTitle
          tagPageImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
